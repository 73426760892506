exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-en-dashboard-js": () => import("./../../../src/pages/en/dashboard.js" /* webpackChunkName: "component---src-pages-en-dashboard-js" */),
  "component---src-pages-en-en-screening-js": () => import("./../../../src/pages/en/en-screening.js" /* webpackChunkName: "component---src-pages-en-en-screening-js" */),
  "component---src-pages-en-favourites-js": () => import("./../../../src/pages/en/favourites.js" /* webpackChunkName: "component---src-pages-en-favourites-js" */),
  "component---src-pages-en-glossary-js": () => import("./../../../src/pages/en/glossary.js" /* webpackChunkName: "component---src-pages-en-glossary-js" */),
  "component---src-pages-en-help-js": () => import("./../../../src/pages/en/help.js" /* webpackChunkName: "component---src-pages-en-help-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-language-js": () => import("./../../../src/pages/en/language.js" /* webpackChunkName: "component---src-pages-en-language-js" */),
  "component---src-pages-en-login-js": () => import("./../../../src/pages/en/login.js" /* webpackChunkName: "component---src-pages-en-login-js" */),
  "component---src-pages-en-screening-js": () => import("./../../../src/pages/en/screening.js" /* webpackChunkName: "component---src-pages-en-screening-js" */),
  "component---src-pages-en-screening-select-js": () => import("./../../../src/pages/en/screening-select.js" /* webpackChunkName: "component---src-pages-en-screening-select-js" */),
  "component---src-pages-en-search-js": () => import("./../../../src/pages/en/search.js" /* webpackChunkName: "component---src-pages-en-search-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-nd-dashboard-js": () => import("./../../../src/pages/nd/dashboard.js" /* webpackChunkName: "component---src-pages-nd-dashboard-js" */),
  "component---src-pages-nd-favourites-js": () => import("./../../../src/pages/nd/favourites.js" /* webpackChunkName: "component---src-pages-nd-favourites-js" */),
  "component---src-pages-nd-glossary-js": () => import("./../../../src/pages/nd/glossary.js" /* webpackChunkName: "component---src-pages-nd-glossary-js" */),
  "component---src-pages-nd-help-js": () => import("./../../../src/pages/nd/help.js" /* webpackChunkName: "component---src-pages-nd-help-js" */),
  "component---src-pages-nd-index-js": () => import("./../../../src/pages/nd/index.js" /* webpackChunkName: "component---src-pages-nd-index-js" */),
  "component---src-pages-nd-language-js": () => import("./../../../src/pages/nd/language.js" /* webpackChunkName: "component---src-pages-nd-language-js" */),
  "component---src-pages-nd-login-js": () => import("./../../../src/pages/nd/login.js" /* webpackChunkName: "component---src-pages-nd-login-js" */),
  "component---src-pages-nd-nd-screening-js": () => import("./../../../src/pages/nd/nd-screening.js" /* webpackChunkName: "component---src-pages-nd-nd-screening-js" */),
  "component---src-pages-nd-screening-js": () => import("./../../../src/pages/nd/screening.js" /* webpackChunkName: "component---src-pages-nd-screening-js" */),
  "component---src-pages-nd-search-js": () => import("./../../../src/pages/nd/search.js" /* webpackChunkName: "component---src-pages-nd-search-js" */),
  "component---src-pages-sn-dashboard-js": () => import("./../../../src/pages/sn/dashboard.js" /* webpackChunkName: "component---src-pages-sn-dashboard-js" */),
  "component---src-pages-sn-favourites-js": () => import("./../../../src/pages/sn/favourites.js" /* webpackChunkName: "component---src-pages-sn-favourites-js" */),
  "component---src-pages-sn-glossary-js": () => import("./../../../src/pages/sn/glossary.js" /* webpackChunkName: "component---src-pages-sn-glossary-js" */),
  "component---src-pages-sn-help-js": () => import("./../../../src/pages/sn/help.js" /* webpackChunkName: "component---src-pages-sn-help-js" */),
  "component---src-pages-sn-index-js": () => import("./../../../src/pages/sn/index.js" /* webpackChunkName: "component---src-pages-sn-index-js" */),
  "component---src-pages-sn-language-js": () => import("./../../../src/pages/sn/language.js" /* webpackChunkName: "component---src-pages-sn-language-js" */),
  "component---src-pages-sn-login-js": () => import("./../../../src/pages/sn/login.js" /* webpackChunkName: "component---src-pages-sn-login-js" */),
  "component---src-pages-sn-screening-js": () => import("./../../../src/pages/sn/screening.js" /* webpackChunkName: "component---src-pages-sn-screening-js" */),
  "component---src-pages-sn-search-js": () => import("./../../../src/pages/sn/search.js" /* webpackChunkName: "component---src-pages-sn-search-js" */),
  "component---src-pages-sn-sn-screening-js": () => import("./../../../src/pages/sn/sn-screening.js" /* webpackChunkName: "component---src-pages-sn-sn-screening-js" */),
  "component---src-pages-start-screening-js": () => import("./../../../src/pages/start-screening.js" /* webpackChunkName: "component---src-pages-start-screening-js" */),
  "component---src-pages-survey-js": () => import("./../../../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */),
  "component---src-pages-testdb-js": () => import("./../../../src/pages/testdb.js" /* webpackChunkName: "component---src-pages-testdb-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-category-en-js": () => import("./../../../src/templates/category-en.js" /* webpackChunkName: "component---src-templates-category-en-js" */),
  "component---src-templates-category-nd-js": () => import("./../../../src/templates/category-nd.js" /* webpackChunkName: "component---src-templates-category-nd-js" */),
  "component---src-templates-category-sn-js": () => import("./../../../src/templates/category-sn.js" /* webpackChunkName: "component---src-templates-category-sn-js" */),
  "component---src-templates-helpslideshow-en-js": () => import("./../../../src/templates/helpslideshow-en.js" /* webpackChunkName: "component---src-templates-helpslideshow-en-js" */),
  "component---src-templates-helpslideshow-nd-js": () => import("./../../../src/templates/helpslideshow-nd.js" /* webpackChunkName: "component---src-templates-helpslideshow-nd-js" */),
  "component---src-templates-helpslideshow-sn-js": () => import("./../../../src/templates/helpslideshow-sn.js" /* webpackChunkName: "component---src-templates-helpslideshow-sn-js" */),
  "component---src-templates-myth-en-js": () => import("./../../../src/templates/myth-en.js" /* webpackChunkName: "component---src-templates-myth-en-js" */),
  "component---src-templates-myth-nd-js": () => import("./../../../src/templates/myth-nd.js" /* webpackChunkName: "component---src-templates-myth-nd-js" */),
  "component---src-templates-myth-sn-js": () => import("./../../../src/templates/myth-sn.js" /* webpackChunkName: "component---src-templates-myth-sn-js" */),
  "component---src-templates-questions-en-js": () => import("./../../../src/templates/questions-en.js" /* webpackChunkName: "component---src-templates-questions-en-js" */),
  "component---src-templates-questions-nd-js": () => import("./../../../src/templates/questions-nd.js" /* webpackChunkName: "component---src-templates-questions-nd-js" */),
  "component---src-templates-questions-sn-js": () => import("./../../../src/templates/questions-sn.js" /* webpackChunkName: "component---src-templates-questions-sn-js" */),
  "component---src-templates-quiz-en-js": () => import("./../../../src/templates/quiz-en.js" /* webpackChunkName: "component---src-templates-quiz-en-js" */),
  "component---src-templates-quiz-nd-js": () => import("./../../../src/templates/quiz-nd.js" /* webpackChunkName: "component---src-templates-quiz-nd-js" */),
  "component---src-templates-quiz-sn-js": () => import("./../../../src/templates/quiz-sn.js" /* webpackChunkName: "component---src-templates-quiz-sn-js" */),
  "component---src-templates-slideshow-en-js": () => import("./../../../src/templates/slideshow-en.js" /* webpackChunkName: "component---src-templates-slideshow-en-js" */),
  "component---src-templates-slideshow-nd-js": () => import("./../../../src/templates/slideshow-nd.js" /* webpackChunkName: "component---src-templates-slideshow-nd-js" */),
  "component---src-templates-slideshow-sn-js": () => import("./../../../src/templates/slideshow-sn.js" /* webpackChunkName: "component---src-templates-slideshow-sn-js" */),
  "component---src-templates-unit-en-js": () => import("./../../../src/templates/unit-en.js" /* webpackChunkName: "component---src-templates-unit-en-js" */),
  "component---src-templates-unit-nd-js": () => import("./../../../src/templates/unit-nd.js" /* webpackChunkName: "component---src-templates-unit-nd-js" */),
  "component---src-templates-unit-sn-js": () => import("./../../../src/templates/unit-sn.js" /* webpackChunkName: "component---src-templates-unit-sn-js" */)
}

